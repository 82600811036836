module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Daye","short_name":"Daye","start_url":"/","background_color":"#FFFBE7","theme_color":"#001D3B","display":"standalone","icon":"src/images/DayeMonogram.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"48e281ccb2516c1acbb2aa2c4a921249"},
    },{
      plugin: require('../node_modules/gatsby-plugin-next-seo/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-prismic-previews/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"ecommerce-website","typePrefix":"ecommerce","accessToken":"MC5Ya19PX0JBQUFDSUFOWFlM.77-977-9AO-_ve-_vWhyce-_vS7vv70f77-9Ze-_ve-_vWV1GO-_vX7vv70ICO-_ve-_vTxO77-9HHzvv70"},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"locales","languages":["en","en-us","en-eu"],"defaultLanguage":"en","redirect":false,"generateDefaultLanguagePage":false,"siteUrl":"https://www.yourdaye.com/","i18nextOptions":{"debug":false,"saveMissing":false,"interpolation":{"escapeValue":false},"nsSeparator":false,"keySeparator":".","lowerCaseLng":true,"fallbackLng":{"en-us":["en"],"en-eu":["en"],"default":["en"]}}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
